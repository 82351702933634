import { bool, func, object, string } from "prop-types";
import React, { useState } from "react";
import { styled } from "styled-components";
import DayExpenseListItem from "../DayExpenseListItem";
import DayExpenseListHeaders from "../DayExpenseListHeaders";
import { Button } from "../Button";
import Loader from "../Loader";
import Colors from "../../styles/Colors";
import Sizes from "../../styles/Sizes";
import { Plus, SortAsc, SortDesc } from "../icons";

const Subheader = styled.h2`
    color: ${Colors.greySecondaryText};
    margin: 0;
    line-height: 1.5em;
`;

const SortButtonWrapper = styled.div`
    visibility: hidden;
`;

const ButtonSection = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
`;

const HSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;

    ${Subheader} {
        flex-grow: 2;
    }

    ${ButtonSection} {
        flex-grow: 1;
    }
`;

const HeadersContainer = styled.div``;

const Week = styled.ul`
    display: flex;
    flex-direction: column;
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 16px;
    box-sizing: border-box;
    align-items: stretch;

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        padding: 0;
    }

    @media (width <= ${Sizes.widthMobileMax}) {
        ${HeadersContainer} {
            display: none;
        }

        ${SortButtonWrapper} {
            visibility: visible;
        }
    }
`;

const StyledDayExpenseList = styled.div`
    flex-grow: 1;
    min-width: ${Sizes.widthMobileMin};
    container-type: inline-size;

    ${({ $width }) => $width && `width: ${$width};`}
    ${({ $disabled }) => $disabled && `color: ${Colors.greyDisabledText};`}
`;

const groupDaysByWeek = (days, descending = false, hidingFuture = false) => {
    return days.reduce((weeks, dayData) => {
        const { dateValue, weekDayNum, byTime } = dayData;

        // Don't add a future day if the hiding flag is set
        if (hidingFuture && byTime === "future") {
            return weeks;
        }

        // Add a week if there's no week yet or if it's the first week day
        const shouldAddWeek = weeks.length === 0 || weekDayNum === 1;

        if (shouldAddWeek) {
            descending ? weeks.unshift([]) : weeks.push([]);
        }

        // Add a day to the last added week
        const week = weeks.at(descending ? 0 : -1);
        const day = <DayExpenseListItem key={dateValue} {...dayData} />;
        descending ? week.unshift(day) : week.push(day);

        return weeks;
    }, []);
};

const DayExpenseListView = ({
    data,
    width,
    defaultDescending = false,
    saveDescending,
}) => {
    const [descending, setDescending] = useState(defaultDescending);

    // While there is no special control for future hiding, hide future if sorting is by descending
    const defaultHidingFuture = defaultDescending;
    const [hidingFuture, setHidingFuture] = useState(defaultHidingFuture);

    const subheader = <Subheader>Переменные расходы</Subheader>;
    if (data === undefined) {
        return (
            <StyledDayExpenseList $width={width} $disabled>
                <FlexWrapper>
                    {subheader}
                    <Loader width="40%" />
                </FlexWrapper>
            </StyledDayExpenseList>
        );
    }

    const { list, addUrl } = data;

    let addButton = <></>;
    if (addUrl) {
        const handleButtonClick = () => window.open(addUrl, "_blank");
        addButton = (
            <Button
                type="line"
                iconLeft={<Plus strokeWidth={1.3} />}
                onClick={handleButtonClick}
            />
        );
    }
    if (list.length === 0) {
        return (
            <StyledDayExpenseList $width={width} $disabled>
                <FlexWrapper>
                    <HSection>
                        {subheader}
                        {addButton}
                    </HSection>
                    Нет данных
                </FlexWrapper>
            </StyledDayExpenseList>
        );
    }

    const dateSortingChangeHandler = () => {
        const newValue = !descending;
        // While there is no special control for future hiding, hide future if sorting is by descending
        setHidingFuture(newValue);
        setDescending(newValue);
        saveDescending?.(newValue);
    };

    const sortButton = (
        <SortButtonWrapper>
            <Button
                type="line"
                iconLeft={descending ? <SortDesc /> : <SortAsc />}
                onClick={dateSortingChangeHandler}
            />
        </SortButtonWrapper>
    );

    return (
        <StyledDayExpenseList $width={width}>
            <FlexWrapper>
                <HSection>
                    {subheader}
                    <ButtonSection>
                        {sortButton}
                        {addButton}
                    </ButtonSection>
                </HSection>
                <ListContainer>
                    <HeadersContainer>
                        <DayExpenseListHeaders
                            dateSortingDescending={descending}
                            onDateSortingChange={dateSortingChangeHandler}
                        />
                    </HeadersContainer>
                    {groupDaysByWeek(list, descending, hidingFuture).map(
                        (week, index) => (
                            <Week key={index}>{week}</Week>
                        )
                    )}
                </ListContainer>
            </FlexWrapper>
        </StyledDayExpenseList>
    );
};

DayExpenseListView.propTypes = {
    data: object,
    width: string,
    defaultDescending: bool,
    saveDescending: func,
};

export default DayExpenseListView;

export { groupDaysByWeek };
